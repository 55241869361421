/*


useExitAnimationFade

    react hook that sets the pageExitAnimation on mount and returns

    exitAnimation is a asyn function that resolves once the gsap timeline is complete

*/

import gsap from "gsap"

const { useContext, useEffect } = require("react")
const { context } = require("../../provider")

const useExitAnimationFade = () => {
  const { setPageExitAnimation } = useContext(context)

  useEffect(() => {
    const exitAnimation = () => {
      return new Promise((resolve, reject) => {
        const tl = gsap.timeline({
          onComplete: () =>
            setTimeout(() => {
              gsap.to(".footer", { backgroundColor: "", duration: 0 })
              resolve()
            }, 200),
        })
        tl.add("start")
          .to(".page", {
            opacity: 0,
            duration: 0.6,
            ease: "power2.out",
          })
          .to(
            ".footer",
            { backgroundColor: "white", duration: 0.6, ease: "power2.out" },
            "start"
          )
      })
    }
    setPageExitAnimation(() => exitAnimation)
    return () => {
      setPageExitAnimation(() => {})
    }
  }, [])
}

export default useExitAnimationFade
