import React, { useContext } from "react"
import GatsbyImage from "gatsby-image"

const Image = props => {
  const { pageData, src, className = "" } = props

  if (!pageData[src]) console.error("image not found:", src)

  return (
    <GatsbyImage
      {...props}
      fluid={pageData[src].childImageSharp.fluid}
      className={`img img--${src} ${className}`}
    />
  )
}
export default Image
